export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"},{"name":"yandex-verification","content":"d9cda6488b982fd1"},{"name":"google-site-verification","content":"x0CP0Jx_pYJAdu-GbUWZaHrmgamF2pr_QAH78ZnZjLA"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"},{"rel":"icon","type":"image/png","href":"/favicon-16x16.png","sizes":"16x16"},{"rel":"icon","type":"image/png","href":"/favicon-32x32.png","sizes":"32x32"},{"rel":"icon","type":"image/png","href":"/android-chrome-192x192.png","sizes":"192x192"},{"rel":"icon","type":"image/png","href":"/android-chrome-512x512.png","sizes":"512x512"},{"rel":"icon","type":"image/png","href":"/apple-touch-icon.png","sizes":"180x180"}],"style":[],"script":[{"src":"https://smartcaptcha.yandexcloud.net/captcha.js?render=onload&onload=onloadSmartCaptcha","defer":true,"async":true},{"src":"https://mc.yandex.ru/metrika/tag.js","async":true,"tagPosition":"head"}],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false